import React from "react";
import "./Photos.css";
import { Link } from "react-router-dom";
import HeaderLight from "../../HeaderLight";
import Footer from "../../FooterLight";

export const Photos = () => {
  return (
    <div className="photos">
      <HeaderLight />
      <div className="folders-container">
        <Link to="/nct" className="link">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">NCT</h2>
          </div>
        </Link>
        <Link to="/straykids" className="link">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">STRAY KIDS</h2>
          </div>
        </Link>
        <Link to="/bewhy" className="link">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">BEWHY</h2>
          </div>
        </Link>
        <Link to="/seventeen" className="link">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">SEVENTEEN</h2>
          </div>
        </Link>
        <Link to="/aomg" className="link">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">AOMG</h2>
          </div>
        </Link>
      </div>
      <div className="folders-container" style={{ paddingBottom: "5%" }}>
        <Link to="/amberliu" className="link">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">AMBER LIU</h2>
          </div>
        </Link>
        <Link to="/keshi" className="link">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">KESHI</h2>
          </div>
        </Link>
        <Link to="/keshi" className="link disabled">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">COMING SOON</h2>
          </div>
        </Link>
        <Link to="/keshi" className="link disabled">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">COMING SOON</h2>
          </div>
        </Link>
        <Link to="/keshi" className="link disabled">
          <div className="folder-container">
            <img
              src={require("../../images/FOLDER-OUTLINE.webp")}
              className="folder"
              alt="folder"
            ></img>
            <h2 className="folder-name">COMING SOON</h2>
          </div>
        </Link>
      </div>
      <Footer />
      <div style={{ paddingBottom: "2%" }}></div>
    </div>
  );
};

export default Photos;

import React from "react";
import "./Contact.css";
import HeaderDark from "../../HeaderDark";

export const Contact = () => {
  return (
    <div className="contact">
      <HeaderDark />
      <div className="container">
        <div className="contact-card">
          <div className="contact-text">Hannah Lee</div>
          <div style={{ textAlign: "start" }}>
            <div className="contact-subtext">4083169132</div>
            <div className="contact-subtext">hhannah@stanford.edu</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import "../portfolio/PortfolioPage.css";
import HeaderLight from "../HeaderLight";

export const Joy = () => {
  return (
    <div className="main">
      <HeaderLight />
      <div className="section">
        <div className="coming-soon">COMING SOON</div>
      </div>
    </div>
  );
};

export default Joy;
